import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrlDelegate pdfViewCtrlShouldZoom:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pencilInteractionDidTap:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrlShouldZoom:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyleViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleViewControllerDelegate annotStyleViewController:minimumValue:forStyle:key:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleViewControllerDelegate annotStyleViewController:maximumValue:forStyle:key:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTBookmarkViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTBookmarkViewControllerDelegate bookmarkViewController:didAddBookmark:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkViewControllerDelegate bookmarkViewController:didRemoveBookmark:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCloudCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCloudCreate.borderEffectIntensity</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCoordinatedDocument.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTCoordinatedDocumentDelegate coordinatedDocumentShouldSave:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCoordinatedDocumentDelegate coordinatedDocumentShouldAutoSave:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTEraser.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTEraser.acceptPencilTouchesOnly</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPolylineEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPolylineEditTool.vertices</div>
<div class="difference"><span class="status added">Added</span> PTPolylineEditTool.selectedVertexIndex</div>
<div class="difference"><span class="status added">Added</span> PTPolylineEditTool.touchStartPoint</div>
<div class="difference"><span class="status added">Added</span> PTPolylineEditTool.touchEndPoint</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSignaturesManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTSignaturesManager imageOfSavedSignatureAtIndex:height:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTSignaturesManager imageOfSavedSignatureAtIndex:dpi:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextSearchViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTextSearchViewController.showsKeyboardOnViewDidAppear</div>
<div class="difference"><span class="status added">Added</span> -[PTTextSearchViewController findText:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextSelectTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTTextSelectTool GetQuadsFromPage:ToPage:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull NSArray&lt;NSValue *&gt; *)GetQuadsFromPage:(int)page1 ToPage:(int)page2</td></tr>
<tr><th>To</th><td class="declaration">- (nullable NSArray&lt;NSValue *&gt; *)GetQuadsFromPage:(int)page1 ToPage:(int)page2</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTool.allowZoom</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolManager.viewController</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate viewControllerForToolManager:]</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}